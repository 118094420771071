// About page (/about)

.markdown {

  .imgFlex {
    display: flex;
    justify-content: center;
    margin:2%;
  }

  img {
    max-width: 70%;
    height: auto;

    &.headerImage {
      position:relative;
      width: 75%;
      transform: translate(25%,0);
      margin: 0 auto;
      text-align: center;
    }
  }
  p {
    margin: auto;
  }
  h3 {
    font-size: 1.4em;
    margin-top: 3em;
  }
  h1 {
    font-size: 1em;
    margin-top: 3em;
  }

}
